@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Work Sans", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@page {
  size: 210mm 297mm; 
  margin: 27mm 16mm 27mm 16mm; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: rgb(54, 54, 54);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(70, 70, 70);
}

::-moz-selection {
  color: black;
  background: rgb(225, 225, 225);
}

::selection {
  color: black;
  background: rgb(225, 225, 225);
}