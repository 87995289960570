.profilePic {
  width: 60%;
  display: flex;
  align-items: center;
  padding: 20px;
}
.profileContainer {
  display: flex;
  margin: 20px;
}
@media only screen and (max-width: 768px) {
  .profileContainer {
    flex-direction: column-reverse;
  }
}
