.subtitle {
  font-size: 25px;
  padding-top: 10px;
}

.intro-text {
  width: 100%;
  text-align: center;
}

.title{
  margin-top: -120px;
}

.main {
  min-height: 93vh;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
}

.buttonContainer {
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
}

.landingbutton {
  width: 200px;
  height: 55px;
}

@media only screen and (min-width: 1200px) {
  .buttonContainer {
    padding: 0 200px;
  }

  .title {
    padding: 0 100px;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 60px;
  }

  .landingbutton {
    width: 150px;
    height: 50px;
  }
}

@media only screen and (max-width: 416px) {
  .title {
    font-size: 30px;
  }
}

