@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200&display=swap");

.mainback {
  min-height: 93vh;
  display: flex;
  padding: 50px 0;
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (max-width: 600px) {
  .heading {
    font-size: 30px;
    display: flex;
    justify-content: center;
  }
}
